/*  --------------------------------------------------

Component ScrollAnimationContainer

Child-Komponente: ScrollProgressTracker

Funktion: Was macht die Komponente? 
    - Errechnet die Scroll Start- und Endwerte, sowie die Blockhöhe relativ zur gesamten Dokumenten-Scrolllänge 
    und stellt diese Werte den Children als props zur Verfügung.
    - Block besteht aus einem Container, der 100vh hoch und sticky ist. Und einen Warpper-Container.
    - Der prop der Scrolllength definiert die Blockhöhe des Wrapper-Blocks. Je höher der Block, 
    desto länger muss gescrollt werden, desto "langsamer" werden vom Scroll-Fortschritt 
    abhängige Animationen abgespielt.

Was muss man beachten: 
    - scrollength als prop angeben. Sollte größer als 100vh sein.
    - Benötigt den useRefScrollProgress-Hook.
    - Returned eine Funktion, mit den Variablen start, end, und blockHeight als Funktionsargumenten. 
    Die Funktionsargumente können von den Children als Prop genutzt werden.
    - Gedacht in Kombination mit der ScrollProgressTracker-Komponente

Beispiel: 
  <ScrollAnimationContainer scrollLength={'300vh'}>
      {(start, end, blockHeight) => (
        <Children/>
      )}
  </ScrollAnimationContainer>

----------------------------------------------------- */

import * as React from 'react';
import { useRef, useLayoutEffect } from "react";
import { motion, useSpring } from "framer-motion";
import useRefScrollProgress from "../Hooks/useRefScrollProgress";
import { Box, Button } from "@chakra-ui/react";
export default function ScrollAnimationContainer({ scrollLength, sticky, children }) {

  const myRef = useRef();
  const { start, end } = useRefScrollProgress(myRef);
  const blockHeight = end - start;
  const MotionBox = motion(Box);

  const spring = useSpring(0, { damping: 400, stiffness: 500 });

  useLayoutEffect(() => {
    spring.onChange((latest) => {
      window.scrollTo(0, latest);
    });
  }, [spring]);

  function moveTo(to) {
    spring.set(window.pageYOffset, false);
    setTimeout(() => {
      spring.set(to);
    }, 50);
  }

  // for automatic scroll: https://codesandbox.io/s/window-scrollto-with-framer-motion-forked-1nsixo?file=/src/App.js

  return (
    <MotionBox
      ref={myRef}
      top={0} position="relative"
      h={scrollLength}
      mx={'auto'}
    >

      {/* <Button onClick={() => moveTo(document.getElementById("scrollAnchor").offsetTop)}>Scroll</Button> */}

      <MotionBox
        className="item"
        h={sticky ? '100vh' : scrollLength}
        display={'grid'}
        alignContent={'center'}
        position={sticky ? 'sticky' : 'static'}
        top={0}
      >
        {children(start, end, blockHeight)}
      </MotionBox>
      {/* <div id="scrollAnchor"></div> */}
    </MotionBox>
  );
};

