import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import AnimatedCountUp from "../DefinitionComponents/Animation-Components/AnimatedCountUp";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import GImage from "../DefinitionComponents/Utility/GImage";
import InViewCallback from "../DefinitionComponents/Utility/UX/InViewCallback";
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger";
const MotionDiv = motion(Box);
const ScrollAppearBoxes = () => {
  const [activePanel, setActive] = useState(0);
  return (
    <>
      <Container
        gridRow="1"
        gridColumn="1"
        variant="layoutContainer"
        zIndex={4}
        h="1100vh"
      >
        <Box
          display="grid"
          alignContent="center"
          w="100%"
          h="100vh"
          border="3px dashed transparent"
          position="sticky"
          top="0"
        >
          <AnimatePresence>
            {activePanel === 1 && (
              <MotionDiv
                gridRow="1"
                gridColumn="1"
                key="item1"
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 50 }}
                exit={{ opacity: 0, y: -50 }}
                textAlign="center"
                color="brand.white"
              >
              <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                <Flex justifyContent="center" maxW="120px" mx="auto" mt={12}>
                  <GImage mr={4}  maxW="16px" src="signet_green.svg" />
                </Flex>
                 </FadeInAnimation>
              
                <Heading color="brand.white" fontSize={{base:'5xl',lg:"8xl"}} lineHeight="normal" mb={{base:1,lg:1}} mt={{base:4,lg:6}}>
                  Investing is Teamwork
                </Heading>
                <Text fontSize={{base:'2xl', lg:"4xl"}}
                  mx="auto"
                  mt={{base:"4",lg:6}}
                  maxW={{ base: "90%", lg: "65%" }}
                >
                  Who plays a role and how the wheels mesh is best illustrated by tokentus' investment process.<br /> In a nutshell.
                </Text>

                <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                <Flex maxW="48px" mx="auto" mt={{base:48,lg:12}}>
                  <GImage  maxW="24px" src="arrow-down.svg" />
                </Flex>
                    </FadeInAnimation>
              
              </MotionDiv>
            )}
            {activePanel === 2 && (
              <MotionDiv
                gridRow="1"
                gridColumn="1"
                key="item1"
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 50 }}
                exit={{ opacity: 0, y: -50 }}
                textAlign="center"
                color="brand.white"
              >
                <Heading display="inline-block" color="brand.green" fontSize={{base:'xl',lg:"2xl"}}>
                  <Flex alignItems="center">
                <InViewTrigger threshold={0.1} triggerOnce={false}> 
                  {(inView) => (
                    <AnimatedCountUp  border="1px solid red"
                      inView={inView} fontSizeUnit="lg" count={1}  fontSize={"2rem"} duration={0.5} unitSpacing={4} numberColor={"brand.white"}
                      textColor="white" cellHeight={"2rem"} letterSpacing={"0rem"} /> 
                  )}
                  </InViewTrigger>&nbsp;&nbsp;&nbsp; —&nbsp;&nbsp; Investing is Teamwork</Flex>
                </Heading>
                <Text
                    fontSize={{base:'2xl',lg:"5xl"}}
                  mx="auto"
                  mt={12}
                  maxW={{ base: "90%", lg: "60%" }}
                >
                  The tokentus investment managers search for and identify an
                  interesting investment target together with <br />co-investors
                  worldwide.
                </Text>
              </MotionDiv>
            )}
            {activePanel === 3 && (
              <MotionDiv
                gridRow="1"
                gridColumn="1"
                key="item1"
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 50 }}
                exit={{ opacity: 0, y: -50 }}
                textAlign="center"
                color="brand.white"
              >
                 <Heading display="inline-block" color="brand.green" fontSize={{base:'xl',lg:"2xl"}}>
                  <Flex alignItems="center">
                <InViewTrigger threshold={0.1} triggerOnce={false}> 
                  {(inView) => (
                    <AnimatedCountUp  border="1px solid red"
                      inView={inView} fontSizeUnit="lg" count={2}  fontSize={"2rem"}  duration={0.5}unitSpacing={4} numberColor={"brand.white"}
                      textColor="white" cellHeight={"2rem"} letterSpacing={"0rem"} /> 
                  )}
                  </InViewTrigger>&nbsp;&nbsp;&nbsp; —&nbsp;&nbsp; Investing is Teamwork</Flex>
                </Heading>
                <Text
                 fontSize={{base:'2xl',lg:"5xl"}}
                  mx="auto"
                  mt={12}
                  maxW={{ base: "90%", lg: "60%" }}
                >
                The target is presented to the CEO of tokentus for preliminary decision. If the decision is positive, an investment proposal will be prepared based on a due diligence.
                </Text>
              </MotionDiv>
            )}
            {activePanel === 4 && (
              <MotionDiv
                gridRow="1"
                gridColumn="1"
                key="item1"
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 50 }}
                exit={{ opacity: 0, y: -50 }}
                textAlign="center"
                color="brand.white"
              >
                 <Heading display="inline-block" color="brand.green" fontSize={{base:'xl',lg:"2xl"}}>
                  <Flex alignItems="center">
                <InViewTrigger threshold={0.1} triggerOnce={false}> 
                  {(inView) => (
                    <AnimatedCountUp  border="1px solid red"
                      inView={inView} fontSizeUnit="lg" count={3}  fontSize={"2rem"}  duration={0.5} unitSpacing={4} numberColor={"brand.white"}
                      textColor="white" cellHeight={"2rem"} letterSpacing={"0rem"} /> 
                  )}
                 </InViewTrigger>&nbsp;&nbsp;&nbsp; —&nbsp;&nbsp; Investing is Teamwork</Flex>
                </Heading>
                <Text
                     fontSize={{base:'2xl',lg:"5xl"}}
                  mx="auto"
                  mt={12}
                  maxW={{ base: "90%", lg: "60%" }}
                >
                 An investment committee, composed of members of the supervisory and advisory board, makes the investment decision.
                </Text>
              </MotionDiv>
            )}
            {activePanel === 5 && (
              <MotionDiv
                gridRow="1"
                gridColumn="1"
                key="item1"
                animate={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 50 }}
                exit={{ opacity: 0, y: -50 }}
                textAlign="center"
                color="brand.white"
              >
                 <Heading display="inline-block" color="brand.green" fontSize={{base:'xl',lg:"2xl"}}>
                  <Flex alignItems="center">
                <InViewTrigger threshold={0.1} triggerOnce={false}> 
                  {(inView) => (
                    <AnimatedCountUp  border="1px solid red"
                      inView={inView} fontSizeUnit="lg" count={4}  fontSize={"2rem"}  duration={0.5} unitSpacing={4} numberColor={"brand.white"}
                      textColor="white" cellHeight={"2rem"} letterSpacing={"0rem"} /> 
                  )}
                </InViewTrigger>&nbsp;&nbsp;&nbsp; —&nbsp;&nbsp; Investing is Teamwork</Flex>
                </Heading>
                <Text
                     fontSize={{base:'2xl',lg:"5xl"}}
                  mx="auto"
                  mt={12}
                  maxW={{ base: "90%", lg: "60%" }}
                >
                  The continuous investment controlling, which follows the
                  investment, is carried out by tokentus' Organization and
                  Operations department.
                </Text>
              </MotionDiv>
            )}
          </AnimatePresence>
        </Box>
      </Container>
      <Container gridRow="1" gridColumn="1" zIndex={3}>
        <Box my={"25vh"} w="100%" border="2px solid transparent">
          <InViewCallback
            threshold={1}
            parentCallback={() => setActive(0)}
          ></InViewCallback>
        </Box>
        <Box my={"100vh"} w="100%" border="2px solid transparent">
          <InViewCallback
            threshold={1}
            parentCallback={() => setActive(1)}
          ></InViewCallback>
        </Box>
        <Box my={"100vh"} w="100%" border="2px solid transparent">
          <InViewCallback
            threshold={1}
            parentCallback={() => setActive(0)}
          ></InViewCallback>
        </Box>
        <Box my={"100vh"} w="100%" border="2px solid transparent">
          <InViewCallback
            threshold={1}
            parentCallback={() => setActive(2)}
          ></InViewCallback>
        </Box>
        <Box my={"100vh"} w="100%" border="2px solid transparent">
          <InViewCallback
            threshold={1}
            parentCallback={() => setActive(0)}
          ></InViewCallback>
        </Box>
        <Box my={"100vh"} w="100%" border="2px solid transparent">
          <InViewCallback
            threshold={1}
            parentCallback={() => setActive(3)}
          ></InViewCallback>
        </Box>
        <Box my={"100vh"} w="100%" border="2px solid transparent">
          <InViewCallback
            threshold={1}
            parentCallback={() => setActive(0)}
          ></InViewCallback>
        </Box>
        <Box my={"100vh"} w="100%" border="2px solid transparent">
          <InViewCallback
            threshold={1}
            parentCallback={() => setActive(4)}
          ></InViewCallback>
        </Box>
        <Box my={"100vh"} w="100%" border="2px solid transparent">
          <InViewCallback
            threshold={1}
            parentCallback={() => setActive(0)}
          ></InViewCallback>
        </Box>
        <Box my={"100vh"} w="100%" border="2px solid transparent">
          <InViewCallback
            threshold={1}
            parentCallback={() => setActive(5)}
          ></InViewCallback>
        </Box>
        <Box my={"100vh"} w="100%" border="2px solid transparent">
          <InViewCallback
            threshold={1}
            parentCallback={() => setActive(0)}
          ></InViewCallback>
        </Box>
      </Container>
    </>
  );
};

export default ScrollAppearBoxes;
