import { Box, Container, Heading, Text, useColorMode } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Heroblock from '../../components/Heroblock';
import IntroComponent2colMonochrome from '../../components/IntroComponent2colMonochrome';
import PortfolioTeaser from '../../components/PortfolioTeaser';
import ScrollAppearBoxes from '../../components/ScrollAppearBoxes';
import TeamAdvisory from '../../components/TeamAdvisoryBoard';
import TeamAufsichtsrat from '../../components/TeamAufsichtsrat';
import TeamQuadLink from '../../components/TeamQuadLink';
import FadeInAnimation from '../../DefinitionComponents/Animation-Components/FadeInAnimation';
import ScrollAnimationContainer from '../../DefinitionComponents/Utility/UX/ScrollAnimationContainer';
import ScrollVideo from '../../DefinitionComponents/Utility/UX/ScrollVideo';
import video from '/static/videostatic/14mini-all-1280.mp4';
const Team = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('light');
  });

  return (
    <>
      <Helmet>
        <title>tokentus | Team</title>
        <meta
          name="description"
          content="Investing is teamwork. Blockchain based investments are gaining traction. We have strong expertise in this young discipline and a successful track record."
        />
        <link
          rel="canonical"
          href="https:/tokentus.com/team"
        />
      </Helmet>

      <Heroblock
        bgColor="black"
        justifyContent={'end'}
        mediaOpacity={0.5}
        video={false}
        h="95vh"
        minH="95vh"
        maxH={{ lg: '95vh', md: 'auto' }}
        mediaSrc={'Tokentus_LowRes_Gruppen-8764.jpg'}>
        <Container
          variant="layoutContainer"
          py={12}
          px={{ base: 0, lg: '8' }}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}>
            <Text
              color="white"
              fontWeight="normal"
              fontSize="xl">
              We for you
            </Text>
          </FadeInAnimation>

          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}>
            <Text
              color="brand.white"
              fontWeight="normal"
              fontSize={{ base: '5xl', lg: '6xl' }}>
              Experts.
            </Text>
          </FadeInAnimation>

          <FadeInAnimation
            threshold={0.4}
            duration={1}
            delay={0.4}>
            <Heading
              fontSize={{ base: 'xl', lg: '2xl' }}
              maxW={{ base: '100%', lg: '52%' }}
              fontWeight="normal"
              mb="8"
              color="brand.white">
              Staff, supervisory board, advisors and co-investors.
            </Heading>
          </FadeInAnimation>
        </Container>
      </Heroblock>
      <Box
        h={0.5}
        zIndex={1}
        position="relative"
        top="0px"
        w={'100%'}
        bg="white"></Box>
      <Box className="set01">
        <ScrollAnimationContainer scrollLength={'1100vh'}>
          {(start, end, blockHeight) => (
            <>
              <ScrollVideo
                start={start}
                end={end}
                blockHeight={blockHeight}
                scrollStartFactor={0}
                scrollEndFactor={0}
                startValue={0}
                endValue={100}
                videosrc={video}
                fps={25}
                scrollHeight="1100vh"
                showProgressLine={true}
                progressLineColor="#00ff99"
              />

              <ScrollAppearBoxes />
            </>
          )}
        </ScrollAnimationContainer>
      </Box>
      <IntroComponent2colMonochrome
        variant=""
        headline="Meet us. Join us."
        text1="We are dedicated experts. We are focused on our value and your success."
        text2="Our investment targets benefit from our competence and experience. Your vision will be supported and helped along the path to success. We are partners."
      />
      <TeamQuadLink />
      <IntroComponent2colMonochrome
        variant=""
        headline="Supervisory Board."
        text1="The supervisory board is a mandatory unit for a German stock corporation."
        text2="As elected representatives of the shareholders, they support the executive board in an advisory and auditing capacity. Blockchain know-how, entrepreneurship and start-up support as well as finance knowledge are represented equally."
      />
      <TeamAufsichtsrat />
      <IntroComponent2colMonochrome
        variant=""
        headline="Advisory Board."
        text1="The advisory board is an additional and voluntary unit implemented by tokentus."
        text2="The members of the advisory board add additional manpower and expertise to the tokentus team. The board can be reinforced as needed. The current main task is to participate in the investment committee."
      />
      <TeamAdvisory />
      {/* NEWS */}

      <IntroComponent2colMonochrome
        colorvariant="blue"
        headline="Co-Investors."
        text1="Anyone who invests in tokentus also automatically invests with the best blockchain VCs around the world."
        text2="Archax, Blockchain.com, Pantera, Maven 11, gumiCryptos Capital, Paradigm, Polychain Capital, CMT Digital, Digital Currency Group, Cherry Ventures, Coinbase, 1kx, Wintermute, Spartan Group, Sequoia Capital, CMS Holdings."
      />
      {/* <Coinvestorsgrid /> */}
      <PortfolioTeaser />
      <Container
        px={{ base: 0, lg: '8' }}
        variant="layoutContainer"
        mt={0.5}
        mb={0.5}
        display="grid"
        gridGap={0.5}
        gridTemplateColumns={{
          sm: 'repeat(1, 1fr)',
        }}></Container>
    </>
  );
};

export default Team;
