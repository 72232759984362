/*  --------------------------------------------------

Component ScrollVideo
    
----------------------------------------------------- */

import { Box } from '@chakra-ui/react';
import { useTransform, useScroll } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
const MotionBox = motion(Box);
export default function ScrollVideo({
  start,
  end,
  startValue,
  endValue,
  blockHeight,
  scrollStartFactor,
  scrollEndFactor,
  videosrc,
  fps,
  scrollHeight,
  showProgressLine,
  progressLineColor,
}) {
  console.log('ScrollVideoFPS' + fps);
  const { scrollYProgress } = useScroll();
  const scrollThreshold = blockHeight * scrollStartFactor;
  const scrollEndThreshold = blockHeight * scrollEndFactor;
  const yRange = useTransform(
    scrollYProgress,
    [start - scrollThreshold, end - scrollEndThreshold],
    [startValue, endValue]
  );

  const videoRef = useRef();
  const [progress, setProgress] = useState(false);
  useEffect(() => {
    videoRef.current.pause();
  });
  useEffect(() => {
    yRange.onChange((v) => {
      if (videoRef) {
        let frames = videoRef.current.duration * fps;
        console.log('FrameComplete:' + frames);
        let videoTime = v / 25;

        videoRef.current.currentTime = isNaN(videoTime) ? 0 : videoTime * 3;
        console.log('FrameCurrent:' + videoTime);
        setProgress(Math.floor(v));
        console.log('V:' + v);
      }
    });
  }, [yRange]);
  return (
    <>
      <Box
        gridRow="1"
        gridColumn="1"
        position="sticky"
        top="0"
        height={scrollHeight}
        bg="black">
        {showProgressLine && (
          <MotionBox
            position="sticky"
            top="0"
            left="0"
            w="4px"
            bg="brand.green"
            animate={{ height: progress + 'vh' }}
            zIndex={9999}
          />
        )}
        <video
          ref={videoRef}
          muted
          preload="auto"
          autoPlay
          style={{
            width: '100%',
            objectFit: 'cover',
            position: 'sticky',
            top: '0',
            height: '100vh',
            opacity: '0.7',
          }}>
          <source
            type="video/mp4"
            src={videosrc}
          />
        </video>
      </Box>
    </>
  );
}
