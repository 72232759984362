import { Box, Button, Container, Text } from '@chakra-ui/react';
import { Link } from 'gatsby';
import React from 'react';
import FadeInAnimation from '../DefinitionComponents/Animation-Components/FadeInAnimation';
import GImage from '../DefinitionComponents/Utility/GImage';

function TeamAdvisory() {
    return (
        <Container
            variant='layoutContainer'
            mt={0.5}
            mb={0.5}
            px={8}
            display='grid'
            gridGap={0.5}
            gridTemplateColumns={{
                lg: 'repeat(4, 1fr)',
                md: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
            }}>
            <Box
                w='100%'
                delay={0}
                duration={1}
                threshold={0.4}
                initialY={0}
                initialX={0}
                video={false}
                mediaOpacity={0.5}
                bgColor='brand.blue'>
                {/* Use the children you like */}
                <Box p={8}>
                    <FadeInAnimation
                        threshold={0.4}
                        delay={0.4}
                        duration={0.7}
                        initialX={0}
                        initialY={-10}>
                        <Text
                            color='white'
                            fontSize='2xl'>
                            Kay Meyer
                        </Text>
                    </FadeInAnimation>
                    <FadeInAnimation
                        threshold={0.4}
                        delay={1}
                        duration={0.7}
                        initialX={0}
                        initialY={10}>
                        <Text
                            color='brand.lightgray'
                            fontSize='sm'>
                            Investor and Growth Specialist
                            <br />
                            <br />
                        </Text>
                    </FadeInAnimation>
                    <FadeInAnimation
                        threshold={0.4}
                        delay={1}
                        duration={0.7}
                        initialX={0}
                        initialY={10}>
                        <Link href='https://www.linkedin.com/in/kay-meyer-84371512/'>
                            <Button
                                color='brand.green'
                                bg='brand.black'
                                size={'sm'}
                                mt={2}
                                fontWeight={'normal'}
                                px={6}
                                variant='solid'
                                leftIcon={
                                    <GImage
                                        w='14px'
                                        h='12px'
                                        src='icon_linkedin.svg'
                                    />
                                }>
                                LinkedIn
                            </Button>
                        </Link>
                    </FadeInAnimation>
                </Box>
            </Box>
            <Box
                w='100%'
                delay={0}
                duration={1}
                threshold={0.4}
                initialY={0}
                initialX={0}
                video={false}
                mediaOpacity={0.5}
                bgColor='brand.blue'>
                <Box p={8}>
                    <FadeInAnimation
                        threshold={0.4}
                        delay={0.4}
                        duration={0.7}
                        initialX={0}
                        initialY={-10}>
                        <Text
                            color='white'
                            fontSize='2xl'>
                            Sarah Rentschler-Gerloff
                        </Text>
                    </FadeInAnimation>

                    <FadeInAnimation
                        threshold={0.4}
                        delay={1}
                        duration={0.7}
                        initialX={0}
                        initialY={10}>
                        <Text
                            color='brand.lightgray'
                            fontSize='sm'>
                            Head of Digital Assets & Custody, Consultant
                        </Text>
                    </FadeInAnimation>
                    <FadeInAnimation
                        threshold={0.4}
                        delay={1}
                        duration={0.7}
                        initialX={0}
                        initialY={10}>
                        <Link href='https://www.linkedin.com/in/sarah-rentschler-gerloff/'>
                            <Button
                                color='brand.green'
                                bg='brand.black'
                                size={'sm'}
                                mt={2}
                                fontWeight={'normal'}
                                px={6}
                                variant='solid'
                                leftIcon={
                                    <GImage
                                        w='14px'
                                        h='12px'
                                        src='icon_linkedin.svg'
                                    />
                                }>
                                LinkedIn
                            </Button>
                        </Link>
                    </FadeInAnimation>
                </Box>
            </Box>
            <Box
                w='100%'
                delay={0}
                duration={1}
                threshold={0.4}
                initialY={0}
                initialX={0}
                video={false}
                mediaOpacity={0.5}
                bgColor='brand.blue'>
                <Box p={8}>
                    <FadeInAnimation
                        threshold={0.4}
                        delay={0.4}
                        duration={0.7}
                        initialX={0}
                        initialY={-10}>
                        <Text
                            color='white'
                            fontSize='2xl'>
                            Metin Dogan
                        </Text>
                    </FadeInAnimation>

                    <FadeInAnimation
                        threshold={0.4}
                        delay={1}
                        duration={0.7}
                        initialX={0}
                        initialY={10}>
                        <Text
                            color='brand.lightgray'
                            fontSize='sm'>
                            Serial Entrepreneur and Angel Investor
                            <br />
                            <br />
                        </Text>
                    </FadeInAnimation>
                    <FadeInAnimation
                        threshold={0.4}
                        delay={1}
                        duration={0.7}
                        initialX={0}
                        initialY={10}>
                        <Link href='https://www.linkedin.com/in/metin-dogan-73bb41230/'>
                            <Button
                                color='brand.green'
                                bg='brand.black'
                                size={'sm'}
                                mt={2}
                                fontWeight={'normal'}
                                px={6}
                                variant='solid'
                                leftIcon={
                                    <GImage
                                        w='14px'
                                        h='12px'
                                        src='icon_linkedin.svg'
                                    />
                                }>
                                LinkedIn
                            </Button>
                        </Link>
                    </FadeInAnimation>
                </Box>
            </Box>
            <Box
                w='100%'
                delay={0}
                duration={1}
                threshold={0.4}
                initialY={0}
                initialX={0}
                video={false}
                mediaOpacity={0.5}
                bgColor='brand.blue'>
                <Box p={8}>
                    <FadeInAnimation
                        threshold={0.4}
                        delay={0.4}
                        duration={0.7}
                        initialX={0}
                        initialY={-10}>
                        <Text
                            color='white'
                            fontSize='2xl'>
                            Johannes Clauss
                        </Text>
                    </FadeInAnimation>

                    <FadeInAnimation
                        threshold={0.4}
                        delay={1}
                        duration={0.7}
                        initialX={0}
                        initialY={10}>
                        <Text
                            color='brand.lightgray'
                            fontSize='sm'>
                            Serial Entrepreneur, Investor and Mentor
                            <br />
                            <br />
                        </Text>
                    </FadeInAnimation>
                    <FadeInAnimation
                        threshold={0.4}
                        delay={1}
                        duration={0.7}
                        initialX={0}
                        initialY={10}>
                        <Link href='https://www.linkedin.com/in/johannes-clauss-60988a15/'>
                            <Button
                                color='brand.green'
                                bg='brand.black'
                                size={'sm'}
                                mt={2}
                                fontWeight={'normal'}
                                px={6}
                                variant='solid'
                                leftIcon={
                                    <GImage
                                        w='14px'
                                        h='12px'
                                        src='icon_linkedin.svg'
                                    />
                                }>
                                LinkedIn
                            </Button>
                        </Link>
                    </FadeInAnimation>
                </Box>
            </Box>
        </Container>
    );
}

export default TeamAdvisory;
