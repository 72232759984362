import { Box, Button, Container, Text } from '@chakra-ui/react';
import { Link } from 'gatsby';
import React from 'react';
import ImgTextModule from '../components/ImgTextModule';
import FadeInAnimation from '../DefinitionComponents/Animation-Components/FadeInAnimation';
import GImage from '../DefinitionComponents/Utility/GImage';

function TeamAufsichtsrat() {
  return (
    <Container
      variant="layoutContainer"
      mt={0.5}
      mb={0.5}
      display="grid"
      gridGap={0.5}
      gridTemplateColumns={{
        lg: 'repeat(3, 1fr)',
        md: 'repeat(3, 1fr)',
        sm: 'repeat(1, 1fr)',
      }}>
      <ImgTextModule
        minH="400px"
        w="100%"
        src={'aufsicht/Michael_Kollmann.jpg'}
        delay={0.9}
        duration={1}
        threshold={0.4}
        initialY={0}
        initialX={0}
        video={false}
        mediaOpacity={0.8}
        bgColor="black">
        <FadeInAnimation
          threshold={0.4}
          duration={1}>
          <Box p={8}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}>
              <Text
                color="white"
                fontSize="2xl">
                Michael Kollmann
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}>
              <Text
                color="brand.green"
                fontSize="sm">
                Capital Market Specialist and Wealth Manager
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}>
              <Link
                target="_blank"
                href="https://www.xing.com/profile/Michael_Kollmann15/cv">
                <Button
                  color="brand.green"
                  bg="brand.meddarkgray"
                  size={'sm'}
                  mt={2}
                  fontWeight={'normal'}
                  px={6}
                  variant="solid"
                  leftIcon={
                    <GImage
                      w="14px"
                      h="12px"
                      src="icon-xing.svg"
                    />
                  }>
                  Xing
                </Button>
              </Link>
            </FadeInAnimation>
          </Box>
        </FadeInAnimation>
      </ImgTextModule>
      <ImgTextModule
        minH="400px"
        w="100%"
        src={'aufsicht/Rupertus_Rothenhaeuser.jpeg'}
        delay={0.3}
        duration={1}
        threshold={0.4}
        initialY={0}
        initialX={0}
        video={false}
        mediaOpacity={0.8}
        bgColor="black">
        <Box p={8}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}>
            <Text
              color="white"
              fontSize="2xl">
              Rupertus Rothenhäuser
            </Text>
          </FadeInAnimation>

          <FadeInAnimation
            threshold={0.4}
            delay={1}
            duration={0.7}
            initialX={0}
            initialY={10}>
            <Text
              color="brand.green"
              fontSize="sm">
              Digital Asset & Blockchain Specialist, C-Level Management
            </Text>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.4}
            delay={1}
            duration={0.7}
            initialX={0}
            initialY={10}>
            <Link
              target="_blank"
              href="https://www.linkedin.com/in/rupertus-rothenhaeuser-572b713a/">
              <Button
                color="brand.green"
                bg="brand.meddarkgray"
                size={'sm'}
                mt={2}
                fontWeight={'normal'}
                px={6}
                variant="solid"
                leftIcon={
                  <GImage
                    w="14px"
                    h="12px"
                    src="icon_linkedin.svg"
                  />
                }>
                LinkedIn
              </Button>
            </Link>
          </FadeInAnimation>
        </Box>
      </ImgTextModule>

      <ImgTextModule
        minH="400px"
        w="100%"
        src={'aufsicht/Dietrich-Boeke_.smjpg.jpg'}
        delay={0.9}
        duration={1}
        threshold={0.4}
        initialY={0}
        initialX={0}
        video={false}
        mediaOpacity={0.8}
        bgColor="black">
        <FadeInAnimation
          threshold={0.4}
          duration={1}>
          <Box p={8}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}>
              <Text
                color="white"
                fontSize="2xl">
                Dietrich Böke
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}>
              <Text
                color="brand.green"
                fontSize="sm">
                IT and Telecommunications Specialist, C-Level Management
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}>
              <Link
                target="_blank"
                href="https://www.linkedin.com/in/dietrich-böke-6aa561/">
                <Button
                  color="brand.green"
                  bg="brand.meddarkgray"
                  size={'sm'}
                  mt={2}
                  fontWeight={'normal'}
                  px={6}
                  variant="solid"
                  leftIcon={
                    <GImage
                      w="14px"
                      h="12px"
                      src="icon_linkedin.svg"
                    />
                  }>
                  LinkedIn
                </Button>
              </Link>
            </FadeInAnimation>
          </Box>
        </FadeInAnimation>
      </ImgTextModule>
    </Container>
  );
}

export default TeamAufsichtsrat;
