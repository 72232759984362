/*  --------------------------------------------------

Component InViewCallback

Funktion: Was macht die Componente? 
    - Gibt einen Funktionscallback, mit einer Variable "value" als Funktionsargument zurück. Die Funktion kwird in der Parent-Komponente ausgefführt.
    - Callback wird abgefeuert, sobald der Anteil der Komponente, der im Viewport zu sehen ist, den angegebenen threshold-Wert übersteigt.
    - Als Wrapper-Komponente konzipiert.


Was muss man beachten: 
    - threshold als Prop angeben (Wert zwischen 0 und 1, 0 = komplett außerhalb des Viewports, 1 = komplett innerhalb des Viewports)
    - value als Funktionsargument übergeben.
    - parentCallback definieren.
    
Beispiel: 
    const handleCallback = function (arg) {
        console.log(arg);
    };

    return (
        <InViewCallback
            threshold={0.4}
            value={1}
            parentCallback={handleCallback}
        >
            <Children/>
        </InViewCallback>
    )

----------------------------------------------------- */ 

import React from 'react'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useEffect } from 'react';

export default function InViewCallback({ children, threshold, parentCallback, value }) {

    const controls = useAnimation();

    const [ref, inView] = useInView({
        "threshold": threshold,
    });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
            parentCallback(value);
        }
    }, [controls, inView]);


    return (

        <motion.div
            ref={ref}
        >
            {children}
        </motion.div>
    )
}
